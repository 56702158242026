<template>
    <div class="card__wrapper">
        <div class="card__title title-second">Подтвердите почту</div>
        <div class="card__description">
            Для завершения подачи заявки на обучение, проверьте свою электронную почту
            <span style="color: #234996;">{{ currentUser?.email }}</span> и следуйте дальнейшим рекомендациям из письма
        </div>
        <div class="card__form small">
<!--          <router-link to="/" tag="button" class="btn btn-red">Вернуться на главную</router-link>-->
          <a href="https://mait-nauka.ru/" class="btn btn-red">Вернуться на главную</a>
        </div>
    </div>
</template>

<script>

import store from '@/store'

export default {
    beforeRouteEnter(to, from, next) {
        store.dispatch("PERSONAL_FETCH_USER_DATA").finally(() => {
            next();
        })
    },

    computed: {
        currentUser() {
            return this.$store.state.personal?.user;
        }
    },
}
</script>
